<template>
  <div class="mt-24 min-h-50vh">
    <BPageLoader v-if="isLoading" />
    <div
      v-else-if="isFailure"
      class="px-6 py-4 text-center flex flex-col items-center justify-center"
    >
      <div
        class="
          flex
          items-center
          justify-center
          rounded-full
          my-6
          h-28
          w-28
          bg-bg-alternate-3
        "
      >
        <SearchOffIcon />
      </div>
      <h2 class="text-xl max-w-sm text-text-primary font-semibold my-4">
        Expired or Invalid Link
      </h2>
      <p class="text-xs text-text-alternate-3 font-medium my-4">
        We could not verify your Promote and Earn request at this time, please
        the link you are using and try again.
      </p>

      <div class="flex flex-col py-6 gap-4 w-full items-center justify-center">
        <button
          class="
            w-full
            bg-btn-bg-primary
            text-btn-text-primary
            py-4
            rounded
            max-w-sm
            text-sm text-center
            font-bold
          "
          @click="isEmailModal = true"
        >
          Browse Fundraisers
        </button>
      </div>
    </div>
    <div
      v-else-if="isSuccess"
      class="px-6 py-4 text-center flex flex-col items-center mt-10"
    >
      <SuccessIcon class="mb-6 mt-4 mx-auto" />
      <h2 class="text-lg max-w-sm text-text-primary font-semibold my-4">
        Promote and Earn Approved Successfully!
      </h2>

      <div
        class="
          border border-border-primary
          rounded
          pl-1
          h-12
          lg:w-2/4
          mt-6
          text-left
          flex
          items-center
        "
      >
        <p class="text-xs text-text-alternate-1 my-1">
          {{ data }}
        </p>
        <button
          @click="copyLink(data)"
          class="
            h-full
            px-3
            bg-btn-bg-alternate-1
            text-xs
            font-semibold
            text-text-primary
          "
        >
          Copy
        </button>
      </div>
      <a
        class="
          bg-btn-bg-primary
          max-w-sm
          text-btn-text-primary
          rounded
          w-full
          mt-8
          py-4
          font-bold
          text-sm
          focus:outline-none
        "
        :href="data"
        target="_blank"
      >
        Go to your Promote and Earn Page
      </a>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { useApi } from '@/cmp-functions/useApi';
import { verifyPromoteAndEarn } from '@/services/api';
import { toRefs } from 'vue';
import { useToast } from 'vue-toastification';
const route = useRoute();
const toast = useToast();
const ref = route.params.ref;
const id = route.params.promoteAndEarnId;

const [verifyResponse, promoteAndEarnVerification] = useApi(verifyPromoteAndEarn);
promoteAndEarnVerification({ ref, id });

const { isLoading, isSuccess, isFailure, data } = toRefs(verifyResponse);

const copyLink = (text) => {
  navigator.clipboard.writeText(text);

  toast.success('Link Copied!');
};
</script>

<style></style>
